import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SupportRequestService } from '../../shared/support-request.service';
import { ReasonDevice } from '../../models/reasonDevice.model';
import { ReasonDeviceType } from '../../models/reasonDeviceType.model';
import { AuthService } from '../../auth/auth.service';
import { SupportRequest } from '../../shared/support-request.model';
import { Status } from 'src/app/shared/status';
import { CommentService } from 'src/app/shared/comment.service';
import { FileService } from 'src/app/shared/file.service';
import { ConfigurationService } from 'src/app/configuration.service';
import { TimeService } from 'src/app/shared/time.service';
import { Comment } from 'src/app/shared/comment.model';

@Component({
  selector: 'app-support-request',
  templateUrl: './support-request.component.html',
  styleUrls: ['./support-request.component.css']
})
export class SupportRequestComponent implements OnInit {
  
  @Input() supportRequest: SupportRequest;
  @Input() comments: Comment[];
  Status = Status;
  reasonDevice: ReasonDevice;
  reasonDeviceType: ReasonDeviceType;
  interval: any;
  filePaths: string[];

  constructor(public service: SupportRequestService, private authService: AuthService,
    private route: ActivatedRoute, private router: Router, private commentService: CommentService,
    private fileService: FileService, private configurationService: ConfigurationService,
    private timeService: TimeService) { }

  ngOnInit() {
    var supportRequestGuid: string;
    this.route.params.subscribe(params => {
      supportRequestGuid = params['guid']
    });
    if(this.authService.isServicemanOrAdminLoggedIn()) {
      this.router.navigate(['/supportrequest/serviceman/' + supportRequestGuid]);
    } else if(this.authService.isLoggedIn()) {
      this.router.navigate(['/supportrequest/user/' + supportRequestGuid]);
    }
    this.service.getSupportRequestIdByGuid(supportRequestGuid)
      .subscribe(supportRequestId => 
        {
          this.getSupportRequest(supportRequestId);
          this.getComments(supportRequestId);
          this.fileService.getSupportRequestFiles(supportRequestGuid).subscribe(
            res => {
              this.filePaths = res.map(filePath => {
                filePath = this.configurationService.getFileBase() + filePath;
                return filePath;
              });
            },
            err => {
              console.log(err);
            });
          this.interval = setInterval(() => { 
            this.getComments(supportRequestId);
          }, 10000);
        });
  }

  getSupportRequest(supportRequestId: number): void {
    this.service.getSupportRequest(supportRequestId)
      .subscribe(supportRequest => {
        this.supportRequest = supportRequest; 
        this.supportRequest.created = 
          this.timeService.utcDateTimeToLocalDateTime(this.supportRequest.created)
      });
  }

  getComments(supportRequestId: number) { 
    this.commentService.getComments(supportRequestId).subscribe(res => {
      this.comments = res as Comment[];
      this.comments.map(comment=>{
        comment.created = 
          this.timeService.utcDateTimeToLocalDateTime(comment.created)
        return comment
      })
    },
    err => {
      console.log(err);
    });
  }
}