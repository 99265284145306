import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileUploader } from "ng2-file-upload";
import { ToastrService } from 'ngx-toastr';
import { ConfigurationService } from 'src/app/configuration.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  maxFileSize: number = 20 * 1024 * 1024 //20MB
  interval: any;
  rootURL: string;
  public uploader: FileUploader;
  @Input() URL: string;
  @Input() uploadButtonText: string;
  @Output() uploaderToEmit = new EventEmitter();

  constructor(private toastr: ToastrService, private conf: ConfigurationService) {

    this.rootURL = conf.getAppBase();
  }

  ngOnInit() {
    this.uploader = new FileUploader({
      isHTML5: true,
      url: this.rootURL +  this.URL,
      queueLimit: 10,
      maxFileSize: this.maxFileSize
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      switch (filter.name) {
        case 'fileSize':
          this.toastr.error("Dopuszczalny maksymalny rozmiar przesyłanego zdjęcia wynosi 20 MB", 
            "Nie udało się przesłać zdjęcia " + item.name);
          break;
        case 'queueLimit':
          this.toastr.error("Dopuszczalne przesłanie maksymalnie 10 zdjęć", "Nie udało się przesłać zdjęcia " + item.name);
          break;
        default:
          this.toastr.error("Spróbuj ponownie później", "Nie udało się przesłać zdjęcia " + item.name);
          break;
      }
    };
    this.interval = setInterval(() => { 
      this.sendUploader();
    }, 1000);
  }

  sendUploader() {
    this.uploaderToEmit.emit(this.uploader);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
