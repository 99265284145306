import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { SupportRequest } from '../shared/support-request.model';
import { SupportRequestService } from '../shared/support-request.service';
import { TimeService } from '../shared/time.service';

@Component({
  selector: 'app-support-requests',
  templateUrl: './support-requests.component.html',
  styleUrls: ['./support-requests.component.css']
})
export class SupportRequestsComponent implements OnInit {

  supportRequests: SupportRequest[];

  constructor(private service: SupportRequestService, private authService: AuthService,
    private timeService: TimeService) { }

  ngOnInit() {
    if(this.authService.isAdminLoggedIn()) {
      this.getSupportRequestList();
    } else if (this.authService.isServicemanOrAdminLoggedIn()) {
      this.getSupportRequestListForServiceman();
    } else if (this.authService.isLoggedIn()) {
      this.getSupportRequestListForUser();
    }
  }

  getSupportRequestList() {
    this.service.getAllSupportRequests().subscribe(
      res => {
        this.supportRequests = res.sort((sr1, sr2) => sr2.id - sr1.id)
        this.supportRequests.map(sr=>{
          sr.created = this.timeService.utcDateTimeToLocalDateTime(sr.created)
          return sr;
        })
      },
      err => console.log(err)
    );
  }

  getSupportRequestListForServiceman() {
    this.service.getSupportRequestsForServiceman().subscribe(
      res => {
        this.supportRequests = res.sort((sr1, sr2) => sr2.id - sr1.id)
        this.supportRequests.map(sr=>{
          sr.created = this.timeService.utcDateTimeToLocalDateTime(sr.created)
          return sr;
        })
      },
      err => console.log(err)
    );
  }

  getSupportRequestListForUser() {
    this.service.getSupportRequestsForUser().subscribe(
      res => {
        this.supportRequests = res.sort((sr1, sr2) => sr2.id - sr1.id)
        this.supportRequests.map(sr=>{
          sr.created = this.timeService.utcDateTimeToLocalDateTime(sr.created)
          return sr;
        })
      },
      err => console.log(err)
    );
  }

}
