import { Injectable } from '@angular/core';
import { SupportRequest } from './support-request.model';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ConfigurationService } from '../configuration.service';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class SupportRequestService {

  private appBase: string;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private authService: AuthService, private conf: ConfigurationService) {
    this.appBase = conf.getAppBase();
  }

  reasonDevices = [{id: 1, name: 'Automat biletowy', hasTypes: true, reasonDeviceTypes: [
      {id: 1, name: 'Monitor 10”'},
      {id: 2, name: 'Monitor 17”'},
      {id: 3, name: 'Monitor 19”'}]}, 
    {id: 2, name: 'Tablet', hasTypes: true, reasonDeviceTypes: [
      {id: 1, name:'Blow Black Tab 7.4'},
      {id: 2, name:'Overmax Livecore 7032'},
      {id: 3, name:'Krüger&Matz 7'}]}, 
    {id: 3, name: 'Monitor stanowiskowy', hasTypes: false, reasonDeviceTypes: []},
    {id: 4, name: 'Monitor gabinetowy', hasTypes: false, reasonDeviceTypes: []},
    {id: 5, name: 'Monitor zbiorczy', hasTypes: false, reasonDeviceTypes: []},
    {id: 6, name: 'Konsola przywoławcza Windows', hasTypes: false, reasonDeviceTypes: []}, 
    {id: 7, name: 'Konsola przywoławcza Tablet', hasTypes: false, reasonDeviceTypes: []},
    {id: 8, name: 'Konsola przywoławcza Web', hasTypes: false, reasonDeviceTypes: []},
    {id: 9, name: 'Serwer', hasTypes: false, reasonDeviceTypes: []},
    {id: 10, name: 'Panel administratora', hasTypes: false, reasonDeviceTypes: []},
    {id: 11, name: 'System kolejkowy', hasTypes: false, reasonDeviceTypes: []},
    {id: 12, name: 'Monitor Digital Signage', hasTypes: true, reasonDeviceTypes: [
      {id: 1, name: 'Monitor Digital Signage 13”'},
      {id: 2, name: 'Monitor Digital Signage 24”'},
      {id: 3, name: 'Monitor Digital Signage 32”'},
      {id: 4, name: 'Monitor Digital Signage 43”'},
      {id: 5, name: 'Monitor Digital Signage 50”'},
      {id: 6, name: 'Monitor Digital Signage 55”'}]}
  ];

  postSupportRequest(formData: SupportRequest): Observable<SupportRequest> {
    return this.http.post<SupportRequest>(this.appBase + '/supportrequest', formData)
  }

  getAllSupportRequests(): Observable<SupportRequest[]> {
    return this.http.get<SupportRequest[]>(this.appBase + '/supportrequest');
  }

  getSupportRequest(id: number): Observable<SupportRequest> {
    if (this.authService.isServicemanOrAdminLoggedIn()) {
      return this.http.get<SupportRequest>(this.appBase + '/supportrequest/' + id + '/serviceman');
    } else if (this.authService.isLoggedIn()) {
      return this.http.get<SupportRequest>(this.appBase + '/supportrequest/' + id + '/user');
    } else {
      return this.http.get<SupportRequest>(this.appBase + '/supportrequest/' + id);
    }
  }

  getSupportRequestServicemen(supportRequestId: number): Observable<User[]> {
    return this.http.get<User[]>(this.appBase + '/supportrequestserviceman/' + supportRequestId);
  }

  addServicemanToSupportRequest(supportRequestId: number, userId: string) {
    let supportRequestServiceman = {supportRequestId: supportRequestId, userId: userId};
    return this.http.post(this.appBase + '/supportrequestserviceman/', supportRequestServiceman)
  }

  removeServicemanFromSupportRequest(supportRequestId: number, servicemanId: string): Observable<string> {
    return this.http.delete<string>(this.appBase + '/supportrequestserviceman/' + supportRequestId + '/' + servicemanId);
  }

  getSupportRequestIdByGuid(guid: string) {
    return this.http.get<number>(this.appBase + '/supportrequest/id/' + guid);
  }

  getSupportRequestsForServiceman(): Observable<SupportRequest[]> {
    return this.http.get<SupportRequest[]>(this.appBase + '/supportrequest/serviceman');
  }

  getSupportRequestsForUser(): Observable<SupportRequest[]> {
    return this.http.get<SupportRequest[]>(this.appBase + '/supportrequest/user');
  }

  updateSupportRequest(supportRequest: SupportRequest) {
    return this.http.put(this.appBase + '/supportrequest', supportRequest);
  }

  padLeft(text:number, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + String(text)).substr( (size * -1), size) ;
  }
}
