import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private rootURL: string;

  constructor(private http: HttpClient, private conf: ConfigurationService) {
    this.rootURL = conf.getAppBase();
  }

  getSupportRequestFiles(supportRequestGuid: string): Observable<string[]> {
    return this.http.post<string[]>(this.rootURL + '/file', {"path" : supportRequestGuid});
  }
}
