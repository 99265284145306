import { Component, OnInit } from '@angular/core';
import { SupportRequestService } from '../shared/support-request.service';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { ReasonDevice } from '../models/reasonDevice.model';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { Status } from '../shared/status';
import { User } from '../shared/user.model';
import {faBuilding,faStickyNote,faUser,faEnvelope} from '@fortawesome/free-regular-svg-icons'
import {faHome,faQuestion,faPhone} from '@fortawesome/free-solid-svg-icons'
import { SupportRequest } from '../shared/support-request.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-add-support-request',
  templateUrl: './add-support-request.component.html',
  styleUrls: ['./add-support-request.component.css'],
})
export class AddSupportRequestComponent implements OnInit {

  constructor(public service: SupportRequestService, private router: Router, public authService: AuthService,
    private userService: UserService, private toastr: ToastrService, private recaptchaV3Service: ReCaptchaV3Service) { }

  supportRequest: SupportRequest;
  currentReasonDevice: ReasonDevice;
  formSubmitted: boolean = false;
  policyCheckbox: boolean = false;
  costCheckbox: boolean = false;
  uploader: FileUploader;
  fileUploadURL: string = "/file/upload";
  uploadButtonText: string = "Dołącz zdjęcia do zgłoszenia";
  user: User;

  //icons
  buildingIcon = faBuilding;
  homeIcon = faHome;
  questionIcon = faQuestion;
  noteIcon = faStickyNote;
  userIcon = faUser;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;

  ngOnInit() {
    this.resetForm();
    this.currentReasonDevice = {id: 0, name: '', hasTypes: false, reasonDeviceTypes: []};
    if(this.authService.isLoggedIn()){
      this.userService.getUserProfile().subscribe(
        res => {
          this.user = res;
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  resetForm() {
    this.supportRequest = new SupportRequest();
    this.supportRequest.status = Status.Recieved;
  }

  onSubmit() {
    this.formSubmitted = true;
    console.log(this.recaptchaV3Service);
    this.recaptchaV3Service.execute('addSupportRequest')
      .subscribe((token) => this.addSupportRequest(token));
  }

  addSupportRequest(token: string) {
    this.supportRequest.recaptchaToken = token;
    if (this.authService.isLoggedIn()) {
      if (this.isValid()) {
        this.supportRequest.userName = this.user.name;
        this.supportRequest.userSurname = this.user.surname;
        this.supportRequest.email = this.user.email;
        this.supportRequest.phoneNumber = this.user.phoneNumber;
      } else {
        this.toastr.error('Nieprawidłowo wypełniony formularz zgłoszeniowy', 'Prosimy uzupełnić wszystkie pola');
        this.formSubmitted = false;
        return;
      }
    }
    this.service.postSupportRequest(this.supportRequest).subscribe(
      async res => {
        this.resetForm();

        this.uploader.onBeforeUploadItem = (item) => {
          item.withCredentials = false;
          item.url = item.url + "/" + res;
        }
        await this.uploader.uploadAll();
        this.router.navigate(['/supportrequest/' + res]);
      },
      err => {
        this.toastr.error('Nie udało się przesłać formularza', 'Prosimy spróbować ponownie');
        this.formSubmitted = false;
      }
    );
  }

  isValid(): boolean {
    return (this.supportRequest.name != "" && this.supportRequest.address != "" &&
      this.supportRequest.reasonDeviceId != null && this.supportRequest.description != "" &&
      this.supportRequest.recaptchaToken != null)
  }

  setCurrentReasonDevice() {
    for (let i = 0; i < this.service.reasonDevices.length; i++) {
      if(this.supportRequest.reasonDeviceId == this.service.reasonDevices[i].id) {
        this.currentReasonDevice.id = this.service.reasonDevices[i].id;
        this.currentReasonDevice.name = this.service.reasonDevices[i].name;
        this.currentReasonDevice.hasTypes = this.service.reasonDevices[i].hasTypes;
        this.currentReasonDevice.reasonDeviceTypes = new Array();
        for (let j = 0; j < this.service.reasonDevices[i].reasonDeviceTypes.length; j++) {
          this.currentReasonDevice.reasonDeviceTypes.push(this.service.reasonDevices[i].reasonDeviceTypes[j]);
        }
      }
    }
  }

  getUploader(uploader: FileUploader) {
    this.uploader = uploader;
  }
}
