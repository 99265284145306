import { Component, OnInit, Input } from '@angular/core';
import { CommentService } from '../shared/comment.service';
import { Comment } from '../shared/comment.model';
import { NgForm } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '../configuration.service';
import { FileService } from '../shared/file.service';
import { ToastrService } from 'ngx-toastr';
import { SupportRequestService } from '../shared/support-request.service';
import { SupportRequest } from '../shared/support-request.model';
import { User } from '../shared/user.model';
import { FileUploader } from 'ng2-file-upload';
import { UserService } from '../shared/user.service';
import { TimeService } from '../shared/time.service';
import {faUser,faCalendar} from '@fortawesome/free-regular-svg-icons'

@Component({
  selector: 'app-serviceman-comment',
  templateUrl: './serviceman-comment.component.html',
  styleUrls: ['./serviceman-comment.component.css']
})
export class ServicemanCommentComponent implements OnInit {

  @Input() user: User;
  @Input() supportRequest: SupportRequest;
  servicemanComments: Comment[];
  addingServicemanComment: boolean = false;
  servicemanCommentFilePaths: Array<string[]>;
  interval: any;
  uploader: FileUploader;
  fileUploadURL: string;
  uploadButtonText: string = "Dołącz zdjęcia do komentarza";
  assignableToTaskUsers: User[];

  //icons
  userIcon = faUser;
  calendarIcon = faCalendar;

  constructor(public commentService: CommentService, private configurationService: ConfigurationService,
    private fileService: FileService, private toastr: ToastrService, private supportRequestService: SupportRequestService,
    private userService: UserService, private timeService: TimeService) { }

  ngOnInit() {
    this.getServicemanCommentsWithFiles(this.supportRequest.id, this.supportRequest.guid);
    this.interval = setInterval(() => { 
      this.getServicemanComments(this.supportRequest.id);
    }, 30000);
    this.fileUploadURL = "/file/upload/" + this.supportRequest.guid;
    this.userService.getAssignableToTaskUsers().subscribe(
      res => {
        this.assignableToTaskUsers = res;
      },
      err => {
        console.log(err);
      }
    );
    this.resetServicemanCommentForm();
  }

  getServicemanComments(supportRequestId: number) {
    this.commentService.getServicemanComments(supportRequestId).subscribe(res => {
      this.servicemanComments = res as Comment[];
      this.servicemanComments.map(servicemanComment=>{
        servicemanComment.created = 
          this.timeService.utcDateTimeToLocalDateTime(servicemanComment.created)
        return servicemanComment
      })
    },
    err => {
      console.log(err);
    });
  }

  getServicemanCommentsWithFiles(supportRequestId: number, supportRequestGuid: string) {
    this.commentService.getServicemanComments(supportRequestId).subscribe(res => {
      this.servicemanComments = res as Comment[];
      this.servicemanComments.map(servicemanComment=>{
        servicemanComment.created = 
          this.timeService.utcDateTimeToLocalDateTime(servicemanComment.created)
        return servicemanComment
      })
      this.servicemanCommentFilePaths = new Array<string[]>(this.servicemanComments.length);
      for (let i = 0; i < this.servicemanComments.length; i++) {
        let path = supportRequestGuid + "/servicemancomment/" + this.servicemanComments[i].id;
        this.fileService.getSupportRequestFiles(path).subscribe(
          res => {
            let filePaths = res.map(filePath => {
              filePath = this.configurationService.getFileBase() + filePath;
              return filePath;
            });
            this.servicemanCommentFilePaths[i] = filePaths;
          },
          err => {
            console.log(err);
          }); 
      }
    },
    err => {
      console.log(err);
    });
  }

  resetServicemanCommentForm(form?:NgForm) {
    if(form != null) {
      form.resetForm();
    }
    this.commentService.servicemanCommentForm = {
      id: 0,
      author : '',
      message : '',
      created: null,
      serviceman: '',
      taskDeadlineDate: null
    }
  }

  addServicemanComment() {
    this.addingServicemanComment = true;
    //this.getSupportRequest(supportRequestId);
  }

  onSubmitServicemanComment(form:NgForm, supportRequestId: number) {
    this.addingServicemanComment = false;
    form.value.author = this.user.name + " " + this.user.surname;
    if (form.value.serviceman == '' || form.value.taskDeadlineDate == null) {
      this.toastr.error('Nieprawidłowo uzupełniony komentarz', 'Prosimy uzupełnić wszystkie pola poprawnie');
      return;
    }
    this.supportRequest.servicemanEmail = form.value.serviceman;
    this.supportRequest.taskDeadlineDate = this.getDate(form.value.taskDeadlineDate);
    this.commentService.postServicemanComment(form.value, supportRequestId).subscribe(
      commentId => {
        this.uploadServicemanCommentFiles(commentId);
        this.resetServicemanCommentForm(form);
      },
      err => {
        console.log(err); 
      }
    )
    this.supportRequestService.updateSupportRequest(this.supportRequest).subscribe(
      res => { },
      err => {
        console.log(err);
      }
    );
  }

  uploadServicemanCommentFiles(commentId: string) {
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      item.url = item.url + "/servicemancomment/" + commentId;
    }
    this.uploader.uploadAll();
    setTimeout(() => {
      this.uploader.queue = [];
      this.getServicemanCommentsWithFiles(this.supportRequest.id, this.supportRequest.guid);
    }, 1000);
  }

  getDate(ngbDate: NgbDateStruct): Date {
    var date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day + 1)
    return date;
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  getUploader(uploader: FileUploader) {
    this.uploader = uploader;
  }
}
