import { Comment } from './comment.model'
import { ServicemanComment } from './serviceman-comment.model'
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '../configuration.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommentService {

    commentForm: Comment;
    servicemanCommentForm: ServicemanComment;
    private rootURL: string;

    constructor(private http: HttpClient, private conf: ConfigurationService) {
      this.rootURL = conf.getAppBase();
    }

    postCommentForClient(commentForm: Comment, supportRequestId: number): Observable<string> {
        var body = {
          Author: commentForm.author,
          Message: commentForm.message,
          SupportRequestId: supportRequestId
        }
        return this.http.post<string>(this.rootURL + '/comment/forclient', body);
      }

      postCommentForAdmin(commentForm: Comment, supportRequestId: number): Observable<string> {
        var body = {
          Author: commentForm.author,
          Message: commentForm.message,
          SupportRequestId: supportRequestId
        }
        return this.http.post<string>(this.rootURL + '/comment/foradmin', body);
      }
    
      postServicemanComment(servicemanCommentForm: ServicemanComment, supportRequestId: number): Observable<string> {
        var body = {
          author: servicemanCommentForm.author,
          message: servicemanCommentForm.message,
          supportRequestId: supportRequestId,
          servicemanEmail: servicemanCommentForm.serviceman,
          taskDeadlineDate: this.getDate(servicemanCommentForm.taskDeadlineDate)
        }
        return this.http.post<string>(this.rootURL + '/servicemancomment', body);
      }
    
      getComments(id: number) {
        return this.http.get(this.rootURL + '/comment/' + id);
      }
    
      getServicemanComments(id: number) {
        return this.http.get(this.rootURL + '/servicemancomment/' + id);
      }
    
      getSupportRequestComment(supportRequestId: number, commentId: number) {
        return this.http.get(this.rootURL + '/supportrequestcomment/' + supportRequestId + '/' + commentId);
      }

      getDate(ngbDate: NgbDateStruct): Date {
        var date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day + 1)
        return date;
      }
}

