<div class="input-group mt-2">
    <span class="mt-1 mr-1">Wyszukaj po:</span>
    <input type='text' [(ngModel)]='listFilter' (input)="prepareSupportRequests()" class="form-control"/>
    <span class="mt-1 mx-1">Wyszukaj po:</span>
    <select [(ngModel)]="statusFilter" (change)="prepareSupportRequests(); onChangeList()" class="form-control">
        <option [ngValue]="null">Wszystkie</option>
        <option [value]="Status.InProgress">Otwarte</option>
        <option [value]="Status.Done">Zakończone</option>
    </select>
</div>
<div class='row' *ngIf='listFilter'>
    <div class='col-md-6'>
        <h4>Wyszukano po: {{listFilter}}</h4>
    </div>
</div>

<div class="table-responsive">
    <table class="table table-light table-striped my-3">
        <thead class="thead-light">
            <tr>
                <th>#</th>
                <th>Firma</th>
                <th>Status</th>
                <th>Powód</th>
                <th>Data przesłania</th>
                <th>Serwisant</th>
                <th *ngIf="authService.isServicemanOrAdminLoggedIn()">Deadline zadania</th>
                <th *ngIf="authService.isServicemanOrAdminLoggedIn()">Deadline zgłoszenia</th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngIf="supportRequests && user">
            <tr [style.background-color]="getBackgroundColor(sr)"
                *ngFor="let sr of supportRequests | paginate: { itemsPerPage: pageSize, currentPage: page }">
                <th scope="row"> {{ service.padLeft(sr.id, '0', 6) }} </th>
                <td>{{ sr.name }}</td>
                <td>
                    <div [hidden]="changingStatus[sr.id]">
                        <div *ngIf="sr.status == Status.Recieved">Przyjęte</div>
                        <div *ngIf="sr.status == Status.Accepted">Zaakceptowane</div>
                        <div *ngIf="sr.status == Status.InProgress">W realizacji</div>
                        <div *ngIf="sr.status == Status.Done">Zakończone</div>
                        <div [hidden]="!authService.isAdminLoggedIn()">
                            <span class="edit" (click)="changeStatus(sr)">
                                <fa-icon [icon]="editIcon"></fa-icon>
                            </span>
                        </div>
                    </div>
                    <div [hidden]="!changingStatus[sr.id]">
                        <select class="form-control" [(ngModel)]="newStatus" (change)="open(confirmModal)">
                            <option hidden value="" disabled selected></option>
                            <option [ngValue]="Status.Recieved">Przyjęte</option>
                            <option [ngValue]="Status.Accepted">Zaakceptowane</option>
                            <option [ngValue]="Status.InProgress">W realizacji</option>
                            <option [ngValue]="Status.Done">Zakończone</option>
                        </select>
                    
                        <ng-template #confirmModal let-modal>
                            <div class="modal-body">
                                Czy potwierdzasz zmiany i wysyłkę maila do klienta?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger"
                                    (click)="modal.dismiss('Cross click')">Anuluj</button>
                                <button type="submit" class="btn btn-primary"
                                    (click)="modal.close('Save click'); onSubmitStatus()">Tak</button>
                            </div>
                        </ng-template>
                    </div> 
                </td>
                <td>{{ service.reasonDevices[sr.reasonDeviceId - 1].name }}</td>
                <td>{{ sr.created.slice(0,10) }} {{ sr.created.slice(11,16) }}</td>
                <td>
                    <div *ngIf="!sr.servicemanEmail">
                        <div class="withoutServiceman">
                            <fa-icon [icon]="minusIcon" class="mx-1"></fa-icon>
                            <fa-icon [icon]="minusIcon" class="mx-1"></fa-icon>
                        </div>
                    </div>
                    <div *ngIf="sr.servicemanEmail">{{ sr.servicemanEmail }}</div>
                    <div [hidden]="!authService.isAdminLoggedIn()">
                        <button class="btn btn-primary btn-sm"
                            (click)="setCurrentServicemen(sr.servicemans); open(servicemansModal)">
                            <fa-icon [icon]="listIcon"></fa-icon>
                        </button>
                    </div>

                    <ng-template #servicemansModal let-modal>
                        <div class="modal-header">
                            Lista serwisantów mających dostęp do zgłoszenia
                        </div>
                        <div class="modal-body">
                            <ul class="list-group">
                                <div *ngIf="currentServicemen.length == 0">Brak serwisantów</div>
                                <div *ngFor="let serviceman of currentServicemen">
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        {{ serviceman.name }} {{serviceman.surname }}
                                        <button class="btn btn-danger" (click)="deleteServiceman(sr, serviceman)">
                                            <fa-icon [icon]="timesIcon"></fa-icon>
                                        </button>
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="modal-footer" class="d-flex justify-content-between p-2">
                            <select [(ngModel)]="currentServiceman" class="form-control">
                                <option *ngFor="let serviceman of chooseLeftServicemans(sr)"
                                    [ngValue]="serviceman">
                                    {{ serviceman.name }} {{ serviceman.surname }}
                                </option>
                                <option *ngIf="chooseLeftServicemans(sr)?.length == 0" disabled>
                                    brak serwisantów
                                </option>
                            </select>
                            <button *ngIf="!currentServiceman" class="btn btn-primary mx-2" disabled>Dodaj</button>
                            <button *ngIf="currentServiceman" type="submit" class="btn btn-primary mx-2"
                                (click)="addServiceman(sr)">Dodaj</button>
                            <button type="submit" class="btn btn-danger" (click)="modal.close('Save click')">Wyjdź</button>
                        </div>
                    </ng-template>
                </td>
                <td *ngIf="authService.isServicemanOrAdminLoggedIn()">
                    <div *ngIf="sr.taskDeadlineDate==null">brak</div>
                    <div *ngIf="sr.taskDeadlineDate!=null">{{ sr.taskDeadlineDate.slice(0,10) }}</div>                
                </td>
                <td *ngIf="authService.isServicemanOrAdminLoggedIn()">
                    <div [hidden]="sr.deadlineDate!=null || authService.isAdminLoggedIn()">brak</div>
                    <div [hidden]="(!changingDeadline[sr.id] && sr.deadlineDate!=null) || !authService.isAdminLoggedIn()">
                        <div class="input-group">
                            <input [hidden]="true" class="form-control" (ngModelChange)="onChangeDeadline(sr.id)"
                                name="TaskDeadlineDate" [(ngModel)]="deadline" #TaskDeadlineDay="ngModel" ngbDatepicker
                                 #d="ngbDatepicker" [placement]="calendarPlacement" [positionTarget]="calendar"
                                 container="body">
                            <div class="input-group">
                            <button #calendar class="btn btn-primary" (click)="d.toggle()">
                                <fa-icon [icon]="calendarIcon"></fa-icon>
                                </button>
                                <div [hidden]="!confirmDeadline[sr.id]" class="input-group-append">
                                    <button class="btn orange" type="button" (click)="saveDeadline()">
                                        <fa-icon [icon]="checkIcon"></fa-icon>
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="changingDeadline[sr.id] || sr.deadlineDate==null">
                        <div *ngIf="sr.deadlineDate!=null">{{ sr.deadlineDate.slice(0,10) }}</div>
                        <div [hidden]="!authService.isAdminLoggedIn()">
                            <span class="edit" (click)="changeDeadline(sr.id)">
                                <fa-icon [icon]="editIcon"></fa-icon>
                            </span>
                        </div>
                    </div>
                </td>
                <td>
                    <button class="btn btn-primary" type="button" (click)="redirect(sr.guid)"
                        placement="right" ngbTooltip="Przejdź do zgłoszenia" tooltipClass="my-custom-class">
                        <fa-icon [icon]="arrowRightIcon"></fa-icon>
                </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="d-flex justify-content-between p-2">
    <pagination-controls previousLabel="Poprzednia" nextLabel="Następna" (pageChange)="page = $event; onChangeList()">
    </pagination-controls>
  
    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (change)="onChangeList()">
        <option [ngValue]="10">10 zgłoszeń na stronie</option>
        <option [ngValue]="15">15 zgłoszeń na stronie</option>
        <option [ngValue]="20">20 zgłoszeń na stronie</option>
    </select>
</div>
