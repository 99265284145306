<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="navbar-brand">
    <a class="navbar-item">
      <img class="img-responsive" src="assets/qcloudLogo.png" height="53" width="42">
    </a>
  </div>
  <button class="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar"
    aria-controls="navbar" aria-expanded="false">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div id="navbar" class="navbar-collapse collapse">
    <ul class="navbar-nav">
      <li class="nav-item">
        <div [hidden]="service.isServicemanOrAdminLoggedIn()">
          <a class="nav-link" routerLink="/add">Dodaj zgłoszenie</a>
        </div>
      </li>
      <li *ngIf="service.isLoggedIn()" class="nav-item">
        <div>
          <a class="nav-link" routerLink="/supportrequests">
            <span *ngIf="service.isServicemanOrAdminLoggedIn()">Wszystkie zgłoszenia</span>
            <span *ngIf="!service.isServicemanOrAdminLoggedIn()">Moje zgłoszenia</span>
          </a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <div [hidden]="service.isLoggedIn()">
          <a class="nav-link" routerLink="/register">Zarejestruj się</a>
        </div>
        <div [hidden]="!service.isLoggedIn()">
          <a class="nav-link" routerLink="/profile"> <fa-icon [icon]="userIcon"></fa-icon> Mój profil</a>
        </div>
      </li>
      <li class="nav-item">
        <div [hidden]="service.isLoggedIn()">
          <a class="nav-link" routerLink="/login"><fa-icon [icon]="signIcon"></fa-icon>  Zaloguj się</a>
        </div>
        <div [hidden]="!service.isLoggedIn()">
          <a class="nav-link" href="" (click)="service.onLogout()"><fa-icon [icon]="signoutIcon"></fa-icon>  Wyloguj się</a>
        </div>
      </li>
    </ul>
  </div>
</nav>