<div *ngIf="supportRequest">
  <div class="card my-3">  
    <div class="card-header">
      <strong>Zgłoszenie o numerze ID {{ service.padLeft(supportRequest.id, '0', 6) }}</strong>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-8">
          <h5>Nazwa firmy: {{ supportRequest.name }}</h5>
          <h5>Urządzenie: {{ service.reasonDevices[supportRequest.reasonDeviceId - 1].name }}</h5>
          <div *ngIf="supportRequest.reasonDeviceTypeId">
            <h5>Typ urządzenia: {{ service.reasonDevices[supportRequest.reasonDeviceId - 1].reasonDeviceTypes[supportRequest.reasonDeviceTypeId - 1].name }}</h5>
          </div>
          <h5>Opis: {{ supportRequest.description }}</h5>
          <h5>Data dodania zgłoszenia: {{ supportRequest.created.slice(0,10) }}, godzina: {{ supportRequest.created.slice(11,16) }}</h5>
        </div>
        <div class="col-md-4">
          <app-support-request-status [supportRequestStatus]="supportRequest.status"></app-support-request-status>
        </div>

        <app-file-view [filePaths]="filePaths"></app-file-view>

      </div>
    </div>
  </div>
  <app-comment *ngIf="supportRequest && user" [supportRequest]="supportRequest" [user]="user"
    [commentTitle]="commentTitle">
  </app-comment>
</div>