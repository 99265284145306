<footer class="bg-white">
  <div class="container text-center footerMargin">
    <p class="col-md-11 footer-content">
      PROMEDIO |
      <a href="http://www.promedio.net.pl">www.promedio.net.pl</a> |
      tel. +48 660 821 356 |
      e-mail: serwis@promedio.net.pl
      <br>
      Copyrights &copy; PROMEDIO {{ currentYear }}
    </p>
  </div>
</footer>
