import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { SupportRequestService } from 'src/app/shared/support-request.service';
import { ReasonDevice } from 'src/app/models/reasonDevice.model';
import { ReasonDeviceType } from 'src/app/models/reasonDeviceType.model';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/shared/user.service';
import { SupportRequest } from 'src/app/shared/support-request.model';
import { Status } from 'src/app/shared/status';
import { User } from 'src/app/shared/user.model';
import { I18n } from 'src/app/i18n/I18n';
import { CustomDatepickerI18n } from 'src/app/i18n/CustomDatepickerI18n';
import { FileService } from 'src/app/shared/file.service';
import { ConfigurationService } from 'src/app/configuration.service';
import { TimeService } from 'src/app/shared/time.service';

@Component({
  selector: 'app-support-request-serviceman',
  templateUrl: './support-request-serviceman.component.html',
  styleUrls: ['./support-request-serviceman.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class SupportRequestServicemanComponent implements OnInit {

  supportRequest: SupportRequest;
  currentSupportRequest: SupportRequest;
  Status = Status;
  reasonDevice: ReasonDevice;
  reasonDeviceType: ReasonDeviceType;
  user: User;
  filePaths: string[];
  commentTitle: string ="Komentarze wewnętrzne";

  constructor(public service: SupportRequestService, private authService: AuthService,
    private userService: UserService, private route: ActivatedRoute, private router: Router,
    private fileService: FileService, private configurationService: ConfigurationService,
    private timeService: TimeService) { }

  ngOnInit() {
    var supportRequestGuid: string;
    this.route.params.subscribe(params => {
      supportRequestGuid = params['guid']
    });
    if(!this.authService.isLoggedIn()) {
      this.router.navigate(['/supportrequest/' + supportRequestGuid]);
    } else if(!this.authService.isServicemanOrAdminLoggedIn()) {
      this.router.navigate(['/supportrequest/user/' + supportRequestGuid]);
    }
    this.service.getSupportRequestIdByGuid(supportRequestGuid)
      .subscribe(supportRequestId => {
          this.getSupportRequest(supportRequestId);
          this.fileService.getSupportRequestFiles(supportRequestGuid).subscribe(
            res => {
              this.filePaths = res.map(filePath => {
                filePath = this.configurationService.getFileBase() + filePath;
                return filePath;
              });
            },
            err => {
              console.log(err);
            });
        });
    this.userService.getUserProfile().subscribe(
      res => {
        this.user = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  getSupportRequest(supportRequestId: number): void {
    this.service.getSupportRequest(supportRequestId)
      .subscribe(supportRequest => {
        this.supportRequest = supportRequest; 
        this.supportRequest.created = 
          this.timeService.utcDateTimeToLocalDateTime(this.supportRequest.created);
      });
  }
}