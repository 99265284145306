import { Status } from './status';
import { User } from './user.model';

export class SupportRequest {
    id: number;
    guid: string;
    name: string;
    address: string;
    reasonDeviceId: number;
    reasonDeviceTypeId: number;
    description: string;
    userName: string;
    userSurname: string;
    email: string;
    phoneNumber: string;
    status: Status;
    servicemanEmail: string;
    deadlineDate: Date;
    taskDeadlineDate: Date;
    created: string;
    servicemans: User[];
    recaptchaToken: string;

    constructor() {
    }
}
