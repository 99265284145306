import { Component, OnInit, Input } from '@angular/core';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Status } from '../status';

@Component({
  selector: 'app-support-request-status',
  templateUrl: './support-request-status.component.html',
  styleUrls: ['./support-request-status.component.css']
})
export class SupportRequestStatusComponent implements OnInit {

  @Input() supportRequestStatus: number;
  Status = Status;


  //icons
  circleIcon = faCircle;
  checkedCircleIcon = faCheckCircle;
  spinnerIcon = faSpinner;

  constructor() { }

  ngOnInit() {
  }

}
