import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formModel = {
    email: '',
    password: ''
  }

  constructor(private service: UserService, private router: Router,
    private toastr: ToastrService, private authService: AuthService) { }

  ngOnInit() {
    if(localStorage.getItem('token') != null)
      this.router.navigateByUrl('/profile');
  }

  onSubmit(form:NgForm) {
    this.service.login(form.value).subscribe(
      (res:any) => {
        localStorage.setItem('token', res.token);
        if(this.authService.isAdminLoggedIn()) {
          this.router.navigateByUrl('/supportrequests');
        } else {
          this.router.navigateByUrl('/profile');
        }
      },
      err => {
        if(err.status == 400)
          this.toastr.error('Nieprawidłowy email lub hasło', 'Nie udało się zalogować');
        else
          console.log(err);
      }
    );
  }

}
