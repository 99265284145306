<h4>Status zgłoszenia</h4>

<div class="timeline">
    <div class="line text-muted"></div>

    <article class="panel panel-danger panel-outline">
        <div class="panel-heading icon" *ngIf="supportRequestStatus == Status.Recieved">
            <fa-icon [icon]="circleIcon" class="blue"></fa-icon>
        </div>
        <div class="panel-heading icon" *ngIf="supportRequestStatus != Status.Recieved">
        
            <fa-icon [icon]="checkedCircleIcon" class="green"></fa-icon>
        </div>  
        <div class="panel-body">
            Przyjęte
        </div>
    </article>

    <article class="panel panel-danger panel-outline">
        <div class="panel-heading icon" *ngIf="Status.Accepted > supportRequestStatus">
            <fa-icon [icon]="spinnerIcon"></fa-icon>
        </div>
        <div class="panel-heading icon" *ngIf="supportRequestStatus == Status.Accepted">
            <fa-icon [icon]="circleIcon" class="blue"></fa-icon>
        </div>
        <div class="panel-heading icon" *ngIf="supportRequestStatus > Status.Accepted">
            <fa-icon [icon]="checkedCircleIcon" class="green"></fa-icon>
        </div>
        <div class="panel-body">
        Zaakceptowane
        </div>
    </article>

    <article class="panel panel-danger panel-outline">
        <div class="panel-heading icon" *ngIf="Status.InProgress > supportRequestStatus">
            <fa-icon [icon]="spinnerIcon"></fa-icon>
        </div>
        <div class="panel-heading icon" *ngIf="supportRequestStatus == Status.InProgress">
            <fa-icon [icon]="circleIcon" class="blue"></fa-icon>
        </div>
        <div class="panel-heading icon" *ngIf="supportRequestStatus > Status.InProgress">
            <fa-icon [icon]="checkedCircleIcon" class="green"></fa-icon>
        </div>
        <div class="panel-body">
            W realizacji
        </div>
    </article>

    <article class="panel panel-danger panel-outline">
        <div class="panel-heading icon" *ngIf="supportRequestStatus == Status.Done">
            <fa-icon [icon]="checkedCircleIcon" class="green"></fa-icon>
        </div>
        <div class="panel-heading icon" *ngIf="supportRequestStatus != Status.Done">
            <fa-icon [icon]="spinnerIcon"></fa-icon>
        </div>  
        <div class="panel-body">
            Zakończone
        </div>
    </article>
</div>
