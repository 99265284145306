<h2 class="my-4 pt-2 pb-3 bg-light text-center">Prześlij zgłoszenie</h2>

<form #form="ngForm" autocomplete="off">
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text bg-white">
                    <fa-icon [icon]="buildingIcon"
                        [class.green-icon]="name.valid" 
                        [class.red-icon]="name.invalid && name.touched">
                    </fa-icon>
                </div>
            </div>
            <input name="name" #name="ngModel" [(ngModel)]="supportRequest.name"
                class="form-control" placeholder="Nazwa firmy" required>
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger mx-2 my-1">
                <div *ngIf="name.errors.required">Nazwa firmy wymagana</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text bg-white">
                    <fa-icon [icon]="homeIcon"
                        [class.green-icon]="address.valid" 
                        [class.red-icon]="address.invalid && address.touched">
                    </fa-icon>
                </div>
            </div>
            <input name="address" #address="ngModel" [(ngModel)]="supportRequest.address"
                class="form-control" placeholder="Adres firmy" required>
            <div *ngIf="address.invalid && (address.dirty || address.touched)" class="text-danger mx-2 my-1">
                <div *ngIf="address.errors.required">Adres firmy wymagany</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text bg-white">
                    <fa-icon [icon]="questionIcon"
                        [class.green-icon]="reasonDeviceId.valid" 
                        [class.red-icon]="reasonDeviceId.invalid && reasonDeviceId.touched">
                    </fa-icon>
                </div>
            </div>
            <select name="reasonDeviceId" #reasonDeviceId="ngModel" [(ngModel)]="supportRequest.reasonDeviceId"
                (ngModelChange)="setCurrentReasonDevice()" class="form-control" required>
                <option hidden value="" default disabled>Wybierz urządzenie</option>
                <option *ngFor="let reasonDevice of service.reasonDevices" [value]="reasonDevice.id">
                    {{ reasonDevice.name }}
                </option>
            </select>
            <div *ngIf="reasonDeviceId.invalid && (reasonDeviceId.dirty || reasonDeviceId.touched)" class="text-danger mx-2 my-1">
                <div *ngIf="reasonDeviceId.errors.required">Urządzenie wymagane</div>
            </div>
        </div>
    </div>
    <div [hidden]="!currentReasonDevice.hasTypes">
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text bg-white">
                        <fa-icon [icon]="questionIcon"
                            [class.green-icon]="reasonDeviceTypeId.valid" 
                            [class.red-icon]="reasonDeviceTypeId.invalid && reasonDeviceTypeId.touched">
                        </fa-icon>                     
                    </div>
                </div>
                <select name="reasonDeviceTypeId" #reasonDeviceTypeId="ngModel"
                    [(ngModel)]="supportRequest.reasonDeviceTypeId" class="form-control">
                    <option hidden value="" disabled selected>Wybierz typ urządzenia</option>
                    <option *ngFor="let reasonDeviceType of currentReasonDevice.reasonDeviceTypes" [value]="reasonDeviceType.id">
                        {{ reasonDeviceType.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text bg-white">
                    <fa-icon [icon]="noteIcon"
                        [class.green-icon]="description.valid" 
                        [class.red-icon]="description.invalid && description.touched">
                    </fa-icon> 
                </div>
            </div>
            <textarea name="description" #description="ngModel" [(ngModel)]="supportRequest.description"
                class="form-control" placeholder="Opis" required></textarea>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="text-danger mx-2 my-1">
                <div *ngIf="description.errors.required">Opis wymagany</div>
            </div>
        </div>
    </div>
    <div [hidden]="authService.isLoggedIn()">
        <div class="form-row">
            <div class="form-group col-md-5">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white">
                            <fa-icon [icon]="userIcon"
                                [class.green-icon]="userName.valid" 
                                [class.red-icon]="(userName.invalid && userName.touched) || (userSurname.invalid && userSurname.touched)">
                            </fa-icon>
                        </div>
                    </div>
                    <input name="userName" #userName="ngModel" [(ngModel)]="supportRequest.userName"
                        class="form-control" placeholder="Imię" required>
                    <div *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="userName.errors.required">Imię wymagane</div>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-7">
                <div class="input-group">
                    <input name="userSurname" #userSurname="ngModel" [(ngModel)]="supportRequest.userSurname"
                        class="form-control" placeholder="Nazwisko" required>
                    <div *ngIf="userSurname.invalid && (userSurname.dirty || userSurname.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="userSurname.errors.required">Nazwisko wymagane</div>
                    </div>
                </div>   
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text bg-white">
                        <fa-icon [icon]="emailIcon"
                            [class.green-icon]="email.valid" 
                            [class.red-icon]="email.invalid && email.touched">
                        </fa-icon> 
                    </div>
                </div>
                <input name="email" #email="ngModel" [(ngModel)]="supportRequest.email"
                    class="form-control" placeholder="E-mail" required email>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="email.errors.required">E-mail wymagany</div>
                    <div *ngIf="email.errors.email">Nieprawidłowy e-mail</div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text bg-white">
                        <fa-icon [icon]="phoneIcon"
                            [class.green-icon]="phoneNumber.valid" 
                            [class.red-icon]="phoneNumber.invalid && phoneNumber.touched">
                        </fa-icon>
                    </div>
                </div>
                <input name="phoneNumber" #phoneNumber="ngModel" [(ngModel)]="supportRequest.phoneNumber" class="form-control"
                placeholder="Telefon" required minlength="7" maxlength="17" pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$">
                <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="phoneNumber.errors.required">Telefon wymagany</div>
                    <div *ngIf="phoneNumber.errors.minlength">Numer za krótki</div>
                    <div *ngIf="phoneNumber.errors.pattern">Nieprawidłowy numer telefonu</div>
                </div>
            </div>
        </div>
    </div>
    <app-file-upload [URL]="fileUploadURL" [uploadButtonText]="uploadButtonText" (uploaderToEmit)="getUploader($event)">
    </app-file-upload>
    <div class="checkbox">
        <label><strong class="orange">*</strong>
          <input class="mx-1" type="checkbox" [checked]="policyCheckbox" (change)="policyCheckbox = !policyCheckbox">
          Zapoznałem się z <a href="https://qcloud.pl/polityka-prywatnosci-i-wykorzystywania-plikow-cookies/" target="_blank">polityką prywatności</a>
           i wyrażam zgodę na przetwarzanie wprowadzonych przeze mnie danych w celu świadczenia usług serwisowych</label>  
    </div>
    <div class="checkbox">
        <label><strong class="orange">*</strong>
          <input class="mx-1" type="checkbox" [checked]="costCheckbox" (change)="costCheckbox = !costCheckbox">
          W przypadku nieuzasadnionego zgłoszenia serwisowego wyrażam zgodę na wystawienie faktury tytułem pokrycia kosztów przyjazdu/usługi serwisowej zgodnie z
          <a href="/assets/cennik-uslug-serwisowych-Qcloud-2023_Q1.pdf" target="_blank">cennikiem usług serwisu pogwarancyjnego PROMEDIO</a>
           </label>    
    </div>
    <div [hidden]="authService.isLoggedIn()">
        <div class="form-group">
            <div *ngIf="form.invalid">
                <button class="btn btn-primary btn-lg btn-block" type="submit" disabled>Wyślij zgłoszenie</button>
                <label class="text-danger">*Uzupełnij wszystkie pola</label>
            </div>
            <div *ngIf="form.valid">
                <button class="btn btn-primary btn-lg btn-block" [disabled]="formSubmitted || !policyCheckbox || !costCheckbox"
                    type="submit" (click)="onSubmit()">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status" aria-hidden="true"></span>
                    Wyślij zgłoszenie
                </button>
                <label *ngIf="!policyCheckbox || !costCheckbox" class="text-danger">*Uzupełnij wszystkie pola</label>
            </div>
        </div>
    </div>
    <div [hidden]="!authService.isLoggedIn()">
        <div class="form-group">
            <button class="btn btn-primary btn-lg btn-block" [disabled]="formSubmitted || !policyCheckbox || !costCheckbox"
                type="submit" (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status" aria-hidden="true"></span>
                Wyślij zgłoszenie
            </button>
        </div>
    </div>
</form>