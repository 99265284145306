import { Component, OnInit } from '@angular/core';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  //icons
  userIcon = faUser;
  signIcon = faSignInAlt;
  signoutIcon = faSignOutAlt;
  
  
  constructor(public service: AuthService) { }

  ngOnInit() {
  }

}
