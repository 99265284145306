import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  utcDateTimeToLocalDateTime(date: string) {
    return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
  }
}
