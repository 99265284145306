<div class="row mt-2">
    <div *ngFor="let filePath of filePaths" class="col-3">
      <img [src]="filePath" class="img-fluid mb-2" (click)="open(imageModal)" alt="image">

      <ng-template #imageModal let-modal>
        <div class="modal-body">
          <img [src]="filePath" class="img-fluid" alt="image">
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">Zamknij</button>
        </div>
      </ng-template>
    </div>
  </div>