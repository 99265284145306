import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public appConfig;

  constructor(private http: HttpClient) { }

    public loadAppConfig() {
      return this.http.get('/assets/config.json').toPromise().then(data => {
        this.appConfig = data;
      }).catch((err: any) => {
        console.error(err);
      });
    }

    public getAppBase() {
      return this.appConfig.appBase + "/api";
    }

    public getFileBase() {
      return this.appConfig.appBase + '/cdn';
    }
}
