import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddSupportRequestComponent } from './add-support-request/add-support-request.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './auth/auth.guard';
import { SupportRequestUserComponent } from './support-request/support-request-user/support-request-user.component';
import { SupportRequestComponent } from './support-request/support-request/support-request.component';
import { SupportRequestServicemanComponent } from './support-request/support-request-serviceman/support-request-serviceman.component';
import { SupportRequestsComponent } from './support-requests/support-requests.component';


const routes: Routes = [
  { path: '', component: AddSupportRequestComponent },
  { path: 'add', component: AddSupportRequestComponent },
  { path: 'supportrequest/:guid', component: SupportRequestComponent },
  { path: 'supportrequest/user/:guid', component: SupportRequestUserComponent, canActivate:[AuthGuard], data: {permittedRoles: ['Administrator', 'Serviceman', 'User']}  },
  { path: 'supportrequest/serviceman/:guid', component: SupportRequestServicemanComponent, canActivate:[AuthGuard], data: {permittedRoles: ['Administrator', 'Serviceman']}  },
  { path: 'supportrequests', component: SupportRequestsComponent, canActivate:[AuthGuard], data: {permittedRoles: ['Administrator', 'Serviceman', 'User']} },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent, canActivate:[AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
