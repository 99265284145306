<form #form='ngForm' class="text-center border border-light p-5" autocomplete="off" (submit)="onSubmit(form)">

    <p class="h4 mb-4">Logowanie</p>
  
    <!-- Email -->
    <div class="form-group">
      <input type="email" class="form-control mb-4" placeholder="E-mail"
      #email="ngModel" name="email" [(ngModel)]="formModel.email" required>
    </div>
  
    <!-- Password -->
    <div class="form-group">
      <input type="password" class="form-control mb-4" placeholder="Hasło"
      #password="ngModel" name="password" [(ngModel)]="formModel.password" required>  
    </div>
  
    <!-- Sign in button -->
    <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Zaloguj się</button>
  
    <!-- Register -->
    <p>Nie masz konta?
      <a href="/register">Zarejestruj się</a>
    </p>
  
  </form>
