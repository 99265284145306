import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration.service';
import { Observable } from 'rxjs';
import { User } from './user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private rootURL: string;

  constructor(private fb: FormBuilder, private http: HttpClient, private conf: ConfigurationService) {
    this.rootURL = conf.getAppBase();
  }

  formModel = this.fb.group({
    name: ['', [Validators.required]],
    surname: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', [Validators.required, Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"),
      Validators.minLength(7), Validators.maxLength(17)]],
    passwords: this.fb.group({
      password: ['', [Validators.required, Validators.pattern(".*[0-9].*"), Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.comparePasswords })

  });

  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('confirmPassword');
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('password').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }

  register() {
    var body = {
      name: this.formModel.value.name,
      surname: this.formModel.value.surname,
      email: this.formModel.value.email,
      password: this.formModel.value.passwords.password,
      phoneNumber: this.formModel.value.phoneNumber
    };
    return this.http.post(this.rootURL + '/user/register', body);
  }

  login(formData) {
    return this.http.post(this.rootURL + '/user/login', formData);
  }

  getUserProfile(): Observable<User> {
    return this.http.get<User>(this.rootURL + '/user');
  }

  getAssignableToTaskUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.rootURL + '/user/assignabletotask');
  }

  getServicemans(): Observable<User[]> {
    return this.http.get<User[]>(this.rootURL + '/user/servicemans');
  }
}
