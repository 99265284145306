<div *ngIf="uploader && uploader.queue" class="row">
    <div class="col-4">
        <button type="button" (click)="fileInput.click()" class="btn btn-primary mb-3" [disabled]="uploader.queue.length >= 10">
            {{ uploadButtonText }}
        </button>
        <span class="hidden-input">
            <input type="file" #fileInput ng2FileSelect accept="image/*" [uploader]="uploader" multiple/>
        </span>

        <div *ngIf="uploader?.queue?.length != 0" class="mb-3">
            <button type="button" class="btn btn-danger"
                    (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                Usuń wszystkie
            </button>
        </div>
    </div>
    <div class="col-8">
        <div *ngIf="uploader?.queue?.length != 0" class="table-responsive">

            <table class="table table-striped table-light table-sm">
                <thead class="thead-light">
                    <tr>
                        <th>Nazwa pliku</th>
                        <th>Rozmiar</th>
                        <th>Usuwanie</th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of uploader.queue">
                    <td><strong>
                        <div *ngIf="item?.file?.name.length > 30">{{ item?.file?.name | slice:0:30 }}...</div>
                        <div *ngIf="!(item?.file?.name.length > 30)">{{ item?.file?.name }}</div>
                    </strong></td>
                    <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                    <td nowrap>
                        <button type="button" class="btn btn-danger btn-sm" (click)="item.remove()">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>