import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SupportRequestService } from 'src/app/shared/support-request.service';
import { Router } from '@angular/router';
import { SupportRequest } from 'src/app/shared/support-request.model';
import { Status } from 'src/app/shared/status';
import { NgbDateStruct, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18n } from 'src/app/i18n/I18n';
import { CustomDatepickerI18n } from 'src/app/i18n/CustomDatepickerI18n';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/shared/user.model';
import { TimeService } from 'src/app/shared/time.service';
import { UserService } from 'src/app/shared/user.service';
import { faCalendar, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCheck, faList, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-support-request-list',
  templateUrl: './support-request-list.component.html',
  styleUrls: ['./support-request-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class SupportRequestListComponent implements OnInit {

  @Input() allSupportRequests: SupportRequest[];
  supportRequests: SupportRequest[];
  currentSupportRequest: SupportRequest;
  Status = Status;
  changingStatus: boolean[];
  newStatus: Status;
  page: number;
  pageSize: number;
  deadline: NgbDateStruct;
  confirmDeadline: boolean[];
  changingDeadline: boolean[];
  currentServicemen: User[];
  calendarPlacement = 'left';
  servicemans: User[];
  currentServiceman: User;
  user: User;
  listFilter: string;
  statusFilter: Status = null;


  //icons
  editIcon = faEdit;
  minusIcon = faMinus;
  listIcon = faList;
  timesIcon = faTimes;
  calendarIcon = faCalendar;
  checkIcon = faCheck;
  arrowRightIcon = faArrowRight;

  constructor(public service: SupportRequestService, private router: Router, public authService: AuthService,
    private modalService: NgbModal, private userService: UserService, private timeService: TimeService) { }

  ngOnInit() {
    this.page = localStorage.getItem('page') ? +localStorage.getItem('page') : 1;
    this.pageSize = localStorage.getItem('pageSize') ? +localStorage.getItem('pageSize') : 10;
    this.statusFilter = localStorage.getItem('statusFilter') ? +localStorage.getItem('statusFilter') : null;
    if (this.authService.isServicemanOrAdminLoggedIn()) {
      this.userService.getServicemans().subscribe(
        res => this.servicemans = res,
        err => console.log(err)
      );
    }
    this.userService.getUserProfile().subscribe(
      res => this.user = res,
      err => console.log(err)
    );
    this.prepareSupportRequests();
  }

  prepareSupportRequests() {
    this.supportRequests = this.allSupportRequests;
    this.supportRequests = this.listFilter ? this.performFilter(this.listFilter) : this.supportRequests;
    this.supportRequests = this.statusFilter ? this.performStatusFilter(this.statusFilter) : this.supportRequests;

    this.changingStatus = new Array<boolean>(this.supportRequests.length);
    this.confirmDeadline = new Array<boolean>(this.supportRequests.length);
    this.changingDeadline = new Array<boolean>(this.supportRequests.length);
  }

  performFilter(filterBy: string): SupportRequest[] {
    this.page = 1;
    filterBy = filterBy.toLocaleLowerCase();
    return this.supportRequests.filter((supportRequest: SupportRequest) =>
      supportRequest.name.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
      (supportRequest.servicemanEmail != null &&
      supportRequest.servicemanEmail.toLocaleLowerCase().indexOf(filterBy) !== -1) ||
      this.service.reasonDevices[supportRequest.reasonDeviceId -1 ].name.toString().toLocaleLowerCase().indexOf(filterBy) !== -1
    )
  }

  performStatusFilter(filterBy: Status): SupportRequest[] {
    if (filterBy == Status.Done) {
      return this.supportRequests.filter((supportRequest: SupportRequest) =>
        supportRequest.status == filterBy
      )
    } else {
      return this.supportRequests.filter((supportRequest: SupportRequest) =>
        supportRequest.status != Status.Done
      )
    }
  }

  onChangeList() {
    localStorage.setItem('pageSize', this.pageSize.toString());
    localStorage.setItem('page', this.page.toString());
    if (this.statusFilter) {
      localStorage.setItem('statusFilter', this.statusFilter.toString());
    } else {
      localStorage.removeItem('statusFilter');
    }
  }

  redirect(guid: string) {
    if (this.authService.isServicemanOrAdminLoggedIn()) {
      this.router.navigate(['/supportrequest/serviceman/' + guid]);
    } else {
      this.router.navigate(['/supportrequest/user/' + guid]);
    }
  }

  getSupportRequest(supportRequestId: number): void {
    this.service.getSupportRequest(supportRequestId)
      .subscribe(supportRequest => {
        this.currentSupportRequest = supportRequest; 
        this.currentSupportRequest.created = 
          this.timeService.utcDateTimeToLocalDateTime(this.currentSupportRequest.created)
      });
  }

  changeStatus(supportRequest: SupportRequest) {
    this.currentSupportRequest = supportRequest;
    this.changingStatus[supportRequest.id] = true;
  }

  onSubmitStatus() {
    this.currentSupportRequest.status = this.newStatus;
    this.service.updateSupportRequest(this.currentSupportRequest).subscribe(
      res => { },
      err => {
        console.log(err);
      }
    );
    this.changingStatus[this.currentSupportRequest.id] = false;
  }

  changeDeadline(supportRequestId: number) {
    this.changingDeadline[supportRequestId] = true;
  }

  onChangeDeadline(supportRequestId: number) {
    this.confirmDeadline[supportRequestId] = true;
    this.getSupportRequest(supportRequestId);
  }

  saveDeadline() {
    this.currentSupportRequest.deadlineDate = this.getDate(this.deadline);
    this.service.updateSupportRequest(this.currentSupportRequest).subscribe(
      res => { },
      err => {
        console.log(err);
      }
    );
    this.confirmDeadline[this.currentSupportRequest.id] = false;
    this.changingDeadline[this.currentSupportRequest.id] = false;
  }

  getDate(ngbDate: NgbDateStruct): Date {
    var date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day + 1)
    return date;
  }

  getBackgroundColor(supportRequest: SupportRequest) {
    if(supportRequest.taskDeadlineDate != null) {
      var now = new Date();
      var deadline = new Date(supportRequest.taskDeadlineDate);
      var differenceInTime = deadline.getTime() - now.getTime(); 
      var differenceInDays = differenceInTime / (1000 * 3600 * 24); 
      if (differenceInDays < -1 && supportRequest.status != Status.Done) {
        return "tomato";
      }
    }
    if(supportRequest.status == Status.Recieved) {
      return "palegoldenrod";
    }
    if(supportRequest.status != Status.Done && supportRequest.servicemanEmail == this.user.email) {
      return "lightblue";
    }    
  }

  open(content) {
    this.modalService.open(content);
  }

  setCurrentServicemen(servicemans: User[]) {
    this.currentServicemen = servicemans;
  }

  deleteServiceman(supportRequest: SupportRequest, serviceman: User) {
    this.service.removeServicemanFromSupportRequest(supportRequest.id, serviceman.id).subscribe(
      res => {
        this.service.getSupportRequestServicemen(supportRequest.id).subscribe(
          res => {
            supportRequest.servicemans = res;
            this.currentServicemen = res;
            this.currentServiceman = serviceman;
          }
        );
       },
      err => {
        console.log(err);
      }
    );
  }

  addServiceman(supportRequest: SupportRequest) {
    this.service.addServicemanToSupportRequest(supportRequest.id, this.currentServiceman.id).subscribe(
      res => {
        this.service.getSupportRequestServicemen(supportRequest.id).subscribe(
          res => {
            supportRequest.servicemans = res;
            this.currentServicemen = res;
            this.currentServiceman = null;
          }
        );
       },
      err => {
        console.log(err);
      }
    )
  }

  chooseLeftServicemans(supportRequest: SupportRequest): User[] {
    let idx = this.supportRequests.findIndex(sR => sR.guid == supportRequest.guid);
    if(this.supportRequests[idx].servicemans != undefined) {
      let leftServicemans = this.servicemans.filter(
        serviceman => this.supportRequests[idx].servicemans.findIndex(srServiceman => srServiceman.id == serviceman.id));
      return leftServicemans;
    }
  }

}
