import { Component, OnInit, Input } from '@angular/core';
import { CommentService } from '../shared/comment.service';
import { Comment } from '../shared/comment.model';
import { NgForm } from '@angular/forms';
import { ConfigurationService } from '../configuration.service';
import { FileService } from '../shared/file.service';
import { TimeService } from '../shared/time.service';
import { User } from '../shared/user.model';
import { SupportRequest } from '../shared/support-request.model';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from '../auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {

  @Input() supportRequest: SupportRequest;
  @Input() user: User;
  @Input() commentTitle: string
  comments: Comment[];
  addingComment: boolean = false;
  commentFilePaths: Array<string[]>;
  interval: any;
  uploader: FileUploader;
  fileUploadURL: string;
  uploadButtonText: string = "Dołącz zdjęcia do komentarza";

  constructor(public commentService: CommentService, private configurationService: ConfigurationService,
    private fileService: FileService, public authService: AuthService, private modalService: NgbModal,
    private timeService: TimeService) { }

  ngOnInit() {
    this.getCommentsWithFiles(this.supportRequest.id, this.supportRequest.guid);
    this.interval = setInterval(() => { 
      this.getComments(this.supportRequest.id);
    }, 30000);
    this.fileUploadURL = "/file/upload/" + this.supportRequest.guid;
    this.resetCommentForm();
  }

  getComments(supportRequestId: number) {
    this.commentService.getComments(supportRequestId).subscribe(res => {
      this.comments = res as Comment[];
      this.comments.map(comment=>{
        comment.created = this.timeService.utcDateTimeToLocalDateTime(comment.created);
        return comment
      })
    },
    err => {
      console.log(err);
    });
  }

  getCommentsWithFiles(supportRequestId: number, supportRequestGuid: string) {
    this.commentService.getComments(supportRequestId).subscribe(res => {
      this.comments = res as Comment[];
      this.comments.map(comment=>{
        comment.created = this.timeService.utcDateTimeToLocalDateTime(comment.created);
        return comment
      })
      this.commentFilePaths = new Array<string[]>(this.comments.length);
      for (let i = 0; i < this.comments.length; i++) {
        let path = supportRequestGuid + "/comment/" + this.comments[i].id;
        this.fileService.getSupportRequestFiles(path).subscribe(
          res => {
            let filePaths = res.map(filePath => {
              filePath = this.configurationService.getFileBase() + filePath;
              return filePath;
            });
            this.commentFilePaths[i] = filePaths;
          },
          err => {
            console.log(err);
          });
        
      }
    },
    err => {
      console.log(err);
    });
  }

  resetCommentForm(form?:NgForm) {
    if(form != null) {
      form.resetForm();
    }
    this.commentService.commentForm = {
      id: 0,
      author : '',
      message : '',
      created: null
    }
  }

  addComment() {
    this.addingComment = true;
  }

  onSubmitCommentForClient(form:NgForm, supportRequestId: number) {
    this.addingComment = false;
    form.value.author = this.user.name + " " + this.user.surname;
    this.commentService.postCommentForClient(form.value, supportRequestId).subscribe(
      commentId => {
        this.uploadCommentFiles(commentId);
        this.resetCommentForm(form);
      },
      err => {
        console.log(err); 
      }
    )
  }

  onSubmitCommentForAdmin(form:NgForm, supportRequestId: number) {
    this.addingComment = false;
    form.value.author = this.user.name + " " + this.user.surname;
    this.commentService.postCommentForAdmin(form.value, supportRequestId).subscribe(
      commentId => {
        this.uploadCommentFiles(commentId);
        this.resetCommentForm(form);
      },
      err => {
        console.log(err); 
      }
    )
  }

  uploadCommentFiles(commentId: string) {
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      item.url = item.url + "/comment/" + commentId;
    }
    this.uploader.uploadAll();
    setTimeout(() => {
      this.uploader.queue = [];
      this.getCommentsWithFiles(this.supportRequest.id, this.supportRequest.guid);
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  getUploader(uploader: FileUploader) {
    this.uploader = uploader;
  }

  open(content) {
    this.modalService.open(content);
  }
}
