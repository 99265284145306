<h4>{{ commentTitle }}</h4>
<div *ngFor="let comment of comments; let i = index">
    <div class="card my-2">
        <div class="card-header">
            <strong>{{ comment.author }}</strong>
        </div>
        <div class="card-body">
            {{ comment.message }}
            <app-file-view [filePaths]="commentFilePaths[i]"></app-file-view>
        </div>
        <div class="card-footer">
            {{ comment.created.slice(0,10) }} {{ comment.created.slice(11,16) }}
        </div>
    </div>
</div>
<div *ngIf="authService.isLoggedIn() && !authService.isServicemanLoggedIn()">
    <div [hidden]="addingComment">
        <button class="btn btn-primary" (click)="addComment()">Dodaj komentarz</button>
    </div>
    <div [hidden]="!addingComment">
        <form #form="ngForm" autocomplete="off">
            <input type="hidden" name="id" [value]="commentService.commentForm.id">
            <div class="card my-2">
                <div class="card-header">
                    <strong>{{ user.name }} {{ user.surname }}</strong>
                </div>
                <textarea class="card-body form-control" name="message" #message="ngModel"
                    [(ngModel)]="commentService.commentForm.message" placeholder="Tekst" required>
                </textarea>
            </div>

            <app-file-upload *ngIf="fileUploadURL" [URL]="fileUploadURL" [uploadButtonText]="uploadButtonText"
                (uploaderToEmit)="getUploader($event)">
            </app-file-upload>

            <div class="form-group">
                <button *ngIf="authService.isAdminLoggedIn()" type="button" class="btn btn-primary btn-lg btn-block"
                    (click)="open(confirmModal)">
                    Prześlij komentarz
                </button>
                <button *ngIf="!authService.isAdminLoggedIn()" class="btn btn-primary btn-lg btn-block" type="submit"
                    (click)="onSubmitCommentForAdmin(form, supportRequest.id)">
                    Prześlij komentarz
                </button>
            </div>

            <ng-template #confirmModal let-modal>
                <div class="modal-body">
                    Czy potwierdzasz zmiany i wysyłkę maila do klienta?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Anuluj</button>
                    <button type="submit" class="btn btn-primary"
                        (click)="modal.close('Save click'); onSubmitCommentForClient(form, supportRequest.id)">
                        Tak
                    </button>
                </div>
            </ng-template>
        </form>
    </div>
</div>