import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { FileUploadModule } from "ng2-file-upload";
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddSupportRequestComponent } from './add-support-request/add-support-request.component';
import { SupportRequestService } from './shared/support-request.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UserService } from './shared/user.service';
import { CommentService } from './shared/comment.service';
import { ProfileComponent } from './profile/profile.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SupportRequestServicemanComponent } from './support-request/support-request-serviceman/support-request-serviceman.component';
import { SupportRequestUserComponent } from './support-request/support-request-user/support-request-user.component';
import { SupportRequestComponent } from './support-request/support-request/support-request.component';
import { ConfigurationService } from './configuration.service';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { SupportRequestStatusComponent } from './shared/support-request-status/support-request-status.component';
import { FileViewComponent } from './shared/file-view/file-view.component';
import { CommentComponent } from './comment/comment.component';
import { ServicemanCommentComponent } from './serviceman-comment/serviceman-comment.component';
import { SupportRequestListComponent } from './support-requests/support-request-list/support-request-list.component';
import { SupportRequestsComponent } from './support-requests/support-requests.component';

const configInitializer = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AddSupportRequestComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    SupportRequestServicemanComponent,
    SupportRequestUserComponent,
    SupportRequestComponent,
    FileUploadComponent,
    SupportRequestStatusComponent,
    FileViewComponent,
    CommentComponent,
    ServicemanCommentComponent,
    SupportRequestListComponent,
    SupportRequestsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
    NgbModule,
    RecaptchaV3Module,
    FileUploadModule,
    NgxPaginationModule
  ],
  providers: [
    UserService,
    SupportRequestService,
    CommentService,
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      multi: true,
      deps: [ConfigurationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Ld-RhQdAAAAANHlZEK5dth5FluZtCRtOL6tEn4I" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
