<h4>Komentarze wewnętrzne</h4>
<div *ngFor="let servicemanComment of servicemanComments; let i = index">
    <div class="card my-2">
        <div class="card-header">
            <strong>{{ servicemanComment.author }}</strong>
        </div>
        <div class="card-body">
            <p>{{ servicemanComment.message }}</p>
            <p>Przypisany serwisant: <strong>{{ servicemanComment.servicemanEmail }}</strong></p>
            <p>Deadline zadania: <strong>{{ servicemanComment.taskDeadlineDate.slice(0,10) }}</strong></p>
            <app-file-view [filePaths]="servicemanCommentFilePaths[i]"></app-file-view>
        </div>
        <div class="card-footer">
            {{ servicemanComment.created.slice(0,10) }} {{ servicemanComment.created.slice(11,16) }}
        </div>
    </div>
</div>
<div [hidden]="addingServicemanComment">
    <button class="btn btn-primary" (click)="addServicemanComment()">Dodaj komentarz</button>
</div>
<div [hidden]="!addingServicemanComment">
    <form #form="ngForm" autocomplete="off" (submit)="onSubmitServicemanComment(form, supportRequest.id)">
        <input type="hidden" name="id" [value]="commentService.servicemanCommentForm.id">

        <div class="card my-2">
            <div class="card-header">
                <strong>{{ user.name }} {{ user.surname }}</strong>
            </div>
            <textarea class="card-body form-control" name="message" #message="ngModel"
                [(ngModel)]="commentService.servicemanCommentForm.message" placeholder="Tekst" required></textarea>

            <div class="input-group">
                <select class="form-control" name="serviceman" #serviceman="ngModel"
                    [(ngModel)]="commentService.servicemanCommentForm.serviceman">
                    <option hidden value="" disabled selected> --- </option>
                    <option *ngFor="let serviceman of assignableToTaskUsers" [value]="serviceman.email">
                        {{ serviceman.name }} {{ serviceman.surname }}
                    </option>
                </select>
                <div class="input-group-append">
                    <fa-icon [icon]="userIcon" class="px-2 py-1"></fa-icon>
                </div>
            </div>

            <div class="input-group">
                <input class="form-control" placeholder="rrrr-mm-dd" name="taskDeadlineDate"
                    [(ngModel)]="commentService.servicemanCommentForm.taskDeadlineDate" #taskDeadlineDay="ngModel"
                    ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-primary" (click)="d.toggle()" type="button">
                        <fa-icon [icon]="calendarIcon"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <app-file-upload *ngIf="fileUploadURL" [URL]="fileUploadURL" [uploadButtonText]="uploadButtonText"
            (uploaderToEmit)="getUploader($event)">
        </app-file-upload>

        <div class="form-group">
            <button class="btn btn-primary btn-lg btn-block" type="submit">Prześlij komentarz</button>
        </div>
    </form>
</div>