<form class="text-center border border-light p-5" [formGroup]="service.formModel" autocomplete="off" (submit)="onSubmit()">

    <p class="h4 mb-4">Rejestracja</p>

    <div class="form-row mb-4">
      <div class="col">
        <!-- Name -->
        <input formControlName="name" class="form-control" placeholder="Imię" required>
        <label class="text-danger" *ngIf="service.formModel.get('name').touched && service.formModel.get('name').errors?.required">Imię wymagane</label>
      </div>
      <div class="col">
        <!-- Surname -->
        <input formControlName="surname" class="form-control" placeholder="Nazwisko" required>
        <label class="text-danger" *ngIf="service.formModel.get('surname').touched && service.formModel.get('surname').errors?.required">Nazwisko wymagane</label>
      </div>
    </div>

    <!-- E-mail -->
    <div class="mb-4">
      <input type="email" formControlName="email" class="form-control" placeholder="E-mail" required>
      <label class="text-danger" *ngIf="service.formModel.get('email').touched && service.formModel.get('email').errors?.required">E-mail wymagany</label>
      <label class="text-danger" *ngIf="service.formModel.get('email').touched && service.formModel.get('email').errors?.email">Nieprawidłowy e-mail</label>
    </div>
    
    <div formGroupName="passwords">
      <!-- Password -->
      <div class="mb-4">
        <input type="password" formControlName="password" class="form-control" placeholder="Hasło" required minlength="6">
        <small class="form-text text-muted">Minimum 6 znaków i jedna cyfra</small>
        <label class="text-danger" *ngIf="service.formModel.get('passwords.password').touched && service.formModel.get('passwords.password').errors?.required">Hasło wymagane</label>
        <label class="text-danger" *ngIf="service.formModel.get('passwords.password').touched && (service.formModel.get('passwords.password').errors?.minlength || service.formModel.get('passwords.password').errors?.pattern)">Hasło niepoprawne</label>
      </div>
      
      <!-- Confirm Password -->
      <div class="mb-4">
        <input type="password" formControlName="confirmPassword" class="form-control" placeholder="Potwierdź hasło" required minlength="6">
        <label class="text-danger" *ngIf="service.formModel.get('passwords.confirmPassword').touched && service.formModel.get('passwords.confirmPassword').errors?.required">Potwierdzenie hasła wymagane</label>
        <label class="text-danger" *ngIf="service.formModel.get('passwords.confirmPassword').touched && service.formModel.get('passwords.confirmPassword').errors?.passwordMismatch">Hasła nie pasują</label>
      </div>
    </div>
    
    <!-- Phone number -->
    <div class="mb-4">
      <input type="text" formControlName="phoneNumber" class="form-control" placeholder="Numer telefonu" required>
      <label class="text-danger" *ngIf="service.formModel.get('phoneNumber').touched && service.formModel.get('phoneNumber').errors?.required">Numer telefonu wymagany</label>
      <label class="text-danger" *ngIf="service.formModel.get('phoneNumber').touched && (service.formModel.get('phoneNumber').errors?.minlength ||
        service.formModel.get('phoneNumber').errors?.pattern)">Numer telefonu niepoprawny</label>
    </div>

    <div class="checkbox">
      <label><strong class="orange">*</strong>
        <input class="mx-1 checkbox checkbox-primary" type="checkbox" [checked]="policyCheckbox" (change)="policyCheckbox = !policyCheckbox">
        Zapoznałem się z <a href="https://qcloud.pl/polityka-prywatnosci-i-wykorzystywania-plikow-cookies/" target="_blank">polityką prywatności</a>
         i wyrażam zgodę na przetwarzanie wprowadzonych przeze mnie danych w celu świadczenia usług serwisowych</label>    
    </div>
    
    <!-- Sign up button -->
    <div class="form-group">
      <button class="btn btn-primary btn-lg btn-block my-4" type="submit" [disabled]="!service.formModel.valid || !policyCheckbox">Zarejestruj się</button>
    </div>

</form>